@font-face {
  font-family: 'Segoe UI Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Segoe UI Regular'), url('./assets/fonts/segoeRegular.woff') format('woff');
}
@font-face {
  font-family: 'Segoe UI Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Segoe UI Italic'), url('./assets/fonts/SegoeItalic.woff') format('woff');
}
@font-face {
  font-family: 'Segoe UI Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Segoe UI Bold'), url('./assets/fonts/SegoeBold.woff') format('woff');
}
@font-face {
  font-family: 'Segoe UI Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Segoe UI Bold Italic'), url('./assets/fonts/segoeBoldItalic.woff') format('woff');
}

html,
body {
  font-size: 14px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  box-sizing: border-box;
}
